@use "styles/breakpoints";
@use "styles/colorSchemes";

:root {
  --body-padding: 10px;
  --grey-01: hsl(0, 0%, 7%);
  --grey-02: hsl(0, 0%, 40%);
  --grey-03: hsl(0, 0%, 60%);
  --grey-04: hsl(0, 0%, 72%);
  --yellow-01: #f4dd7c;
  --blue-01: #4770cf;
  --font-family: "Arial Narrow", arial;
  --heading-1-font: 400 50px/57px var(--font-family);
  --heading-1-letter-spacing: -0.02em;
  --heading-1-transform: uppercase;
  --heading-2-font: 400 25px/29px var(--font-family);
  --heading-2-letter-spacing: -0.02em;
  --heading-2-transform: uppercase;
  --heading-3-font: 400 16px/18px var(--font-family);
  --heading-4-font: 400 12px/14px var(--font-family);

  --z-index-tracks-bananas: 0;
  --z-index-tracks-list: 10;
  --z-index-tracks-banana: 11;
  --z-index-navbar: 12;
  --z-index-eleven-eleven: 13;

  @include breakpoints.sm-up {
    --heading-1-font: 400 70px/80px var(--font-family);
    --heading-2-font: 400 35px/40px var(--font-family);
  }

  @include breakpoints.md-up {
    --body-padding: 20px;
  }

  --short-banana-with-day-clip-path: polygon(
    17% 2%,
    22% 19%,
    29% 37%,
    38% 51%,
    50% 61%,
    59% 67%,
    65% 59%,
    95% 65%,
    99% 75%,
    100% 78%,
    100% 82%,
    100% 85%,
    100% 88%,
    97% 94%,
    89% 99%,
    79% 100%,
    63% 100%,
    51% 100%,
    37% 96%,
    20% 85%,
    6% 69%,
    1% 54%,
    0% 41%,
    0% 28%,
    0% 21%,
    2% 12%,
    4% 5%,
    8% 0%,
    12% 0%,
    16% 0%
  );
}

html,
body {
  display: flex;
  font-family: var(--font-family);
  margin: 0;
  min-height: 100%;
  padding: 0;
  width: 100%;
  background: white;

  @include colorSchemes.dark {
    background: black;
  }
}

body.noScroll {
  height: 100%;
  overflow: hidden;
}

body > div {
  display: flex;
  width: 100%;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-display: optional;
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  src: url("/ArialNarrow.woff2") format("woff2");
}
