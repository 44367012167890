@use "styles/breakpoints";
@use "styles/typography";
@use "styles/colorSchemes";

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title {
  @include typography.heading-2;

  margin: 0;
  color: black;

  @include colorSchemes.dark {
    color: white;
  }
}

.trackId {
  display: none;

  @include breakpoints.sm-up {
    display: initial;
  }
}

.nav {
  display: flex;
  gap: 34px;

  @include breakpoints.sm-up {
    gap: 48px;
  }
}
