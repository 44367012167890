@use "styles/typography";
@use "styles/ctas";
@use "styles/colorSchemes";

.root {
  @include ctas.anchor-reset;
  @include typography.heading-3;

  color: black;
  cursor: pointer;
  padding: 7px 0;
  position: relative;

  @include colorSchemes.dark {
    color: white;
  }

  &::after {
    background-color: #222;
    bottom: -2px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: auto;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.3s cubic-bezier(0.52, 0.14, 0.16, 1.01);
    will-change: transform;

    @include colorSchemes.dark {
      background-color: white;
    }
  }

  &:hover,
  &:focus {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 50%;
      transition: transform 0.4s cubic-bezier(0.52, 0.14, 0.16, 1.01);
    }
  }

  &:active {
    color: var(--blue-01);
  }
}
